body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-mobile-menu {
  display: normal;
}

.footer-col {
  float: left;
  width: 100%;
}

.header-col {
  display: none;
}

.body-col-3 {
  float: left;
  width: 100%;
}

.body-col-2 {
  float: left;
  width: 100%;
}

.mobile-size-1 {
  font-size: 72px;
}

.mobile-size-2 {
  font-size: 48px;
}

.mobile-size-3 {
  font-size: 48px;
}

@media only screen and (min-width: 768px) {
  /* For everything bigger than 768px */
  .header-mobile-menu {
    display: none;
  }

  .footer-col {
    width: 33.33%;
  }

  .header-col {
    display: flex;
  }

  .body-col-3 {
    width: 33.33%;
  }

  .body-col-2 {
    width: 50%;
  }

  .mobile-size-1 {
    font-size: 96px;
  }
  
  .mobile-size-2 {
    font-size: 64px;
  }

  .mobile-size-3 {
    font-size: 48px;
  }
}

/* light */
@font-face {
  font-family: 'segoeui-light';
  src: local('segoeui-light'), url(./components/fonts/Segoeui-Light.ttf) format('truetype');
}

/* regular */
@font-face {
  font-family: 'raleway-regular';
  src: local('raleway-regular'), url(./components/fonts/Raleway-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'roboto-regular';
  src: local('roboto-regular'), url(./components/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'lato-regular';
  src: local('lato-regular'), url(./components/fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'eina01-regular';
  src: local('eina01-regular'), url(./components/fonts/Eina01-Regular.ttf) format('truetype');
}

/* semibold */
@font-face {
  font-family: 'eina01-semibold';
  src: local('eina01-semibold'), url(./components/fonts/Eina01-SemiBold.ttf) format('truetype');
}

/* bold */
@font-face {
  font-family: 'raleway-bold';
  src: local('raleway-bold'), url(./components/fonts/Raleway-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'roboto-bold';
  src: local('roboto-bold'), url(./components/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'lato-bold';
  src: local('lato-bold'), url(./components/fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'eina01-bold';
  src: local('eina01-bold'), url(./components/fonts/Eina01-Bold.ttf) format('truetype');
}

/* extra bold */
@font-face {
  font-family: 'raleway-extrabold';
  src: local('raleway-extrabold'), url(./components/fonts/Raleway-ExtraBold.ttf) format('truetype');
}

/* logo */
@font-face {
  font-family: 'velocity';
  src: local('velocity'), url(./components/fonts/Velocity.ttf) format('truetype');
}

/* icons */
@font-face {
  font-family: AntDesign;
  font-style: normal;
  font-weight: 400;
  src: url(./components/fonts/AntDesign.ttf) format('truetype');
}

.clip-text {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: url("https://c4.wallpaperflare.com/wallpaper/108/760/31/colorful-painting-artwork-swirl-wallpaper-preview.jpg");
  background-size: contain;
}